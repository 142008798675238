.arrow-container{
    display: flex;
    cursor: pointer;
    margin: 0;
    padding: 0;
  }



.maincard{
  margin: - 2em auto auto auto;
  width: 100%;
  height: auto;
}


.sidecardleft{
  height: auto;
  width: 100%;
  margin: 0;
    padding: 0;
}

.sidecardright{
  height: auto;
  width: 100%;
  margin: 0;
    padding: 0;
}


@media only screen and (max-width: 768px) {
  .sidecardleft {
      display: none;
  }

  .sidecardright {
    display: none;
}
}
